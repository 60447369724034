import { lazy } from 'react';

import PrivateRoute from './PrivateRoute';
import { UserRole } from '../../../enum/UserRole';
import Layout from '../Layout/Layout';
import { RoutePath } from '../../../constants/RoutePath';
import { RouteProductDetailsSegments } from '../ProductDetailPage/util/productDetailTabs';
import lazyRetry from '../../../util/lazyRetry';

const LoginPage = lazy(() => lazyRetry(() => import('../LoginPage/LoginPage')));
const SignUpPage = lazy(() => lazyRetry(() => import('../SignUpPage/SignUpPage')));
const Verify2FAPage = lazy(() => lazyRetry(() => import('../Verify2FAPage/Verify2FAPage')));
const ResetPasswordPage = lazy(() => lazyRetry(() => import('../ResetPasswordPage/ResetPasswordPage')));
const ResetPasswordFormPage = lazy(() => lazyRetry(() => import('../ResetPasswordPage/ResetPasswordFormPage')));
const VerifyEmailPage = lazy(() => lazyRetry(() => import('../VerifyEmailPage/VerifyEmailPage')));
const NotVerifiedErrorPage = lazy(() => lazyRetry(() => import('../NotVerifiedErrorPage/NotVerifiedErrorPage')));
const AcceptInvitationPage = lazy(() => lazyRetry(() => import('../AcceptInvitationPage/AcceptInvitationPage')));
const NotFoundPage = lazy(() => lazyRetry(() => import('../NotFoundPage/NotFoundPage')));
const HomePage = lazy(() => lazyRetry(() => import('../HomePage/HomePage')));
const UserInfoPage = lazy(() => lazyRetry(() => import('../UserInfoPage/UserInfoPage')));
const WorkspacesPage = lazy(() => lazyRetry(() => import('../WorkspacesPage/WorkspacesPage')));
const ManageWorkspacePage = lazy(() => lazyRetry(() => import('../ManageWorkspacePage/ManageWorkspacePage')));
const WorkspaceUsersPage = lazy(() => lazyRetry(() => import('../WorkspaceUsersPage/WorkspaceUsersPage')));
const WorkspacePage = lazy(() => lazyRetry(() => import('../Workspace/Workspace')));
const ProductsPage = lazy(() => lazyRetry(() => import('../ProductsPage/ProductsPage')));
const AddWorkspacePage = lazy(() => lazyRetry(() => import('../AddWorkspacePage/AddWorkspacePage')));
const FirstLoginPage = lazy(() => lazyRetry(() => import('../FirstLoginPage/FirstLoginPage')));
const ProductDetailPage = lazy(() => lazyRetry(() => import('../ProductDetailPage/ProductDetailPage')));
const AddProductPage = lazy(() => lazyRetry(() => import('../AddProductPage/AddProductPage')));
const AboutPage = lazy(() => lazyRetry(() => import('../AboutPage/AboutPage')));
const LicensingPage = lazy(() => lazyRetry(() => import('../LicensingPage/LicensingPage')));

const roles = Object.values(UserRole);

export const routerConfig = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.LOGIN,
        element: <LoginPage />,
      },
      {
        path: RoutePath.SIGN_UP,
        element: <SignUpPage />,
      },
      {
        path: RoutePath.FORGOT_PASSWORD,
        element: <ResetPasswordPage />,
      },
      {
        path: RoutePath.RESET_PASSWORD,
        element: <ResetPasswordFormPage />,
      },
      {
        path: RoutePath.VERIFY_EMAIL,
        element: <VerifyEmailPage />,
      },
      {
        path: RoutePath.NOT_VERIFIED,
        element: <NotVerifiedErrorPage />,
      },
      {
        path: `${RoutePath.ACCEPT_INVITATION}/:workspace`,
        element: <AcceptInvitationPage />,
      },
      {
        path: RoutePath.FIRST_LOGIN,
        element: (
          <PrivateRoute>
            <FirstLoginPage />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.ABOUT,
        element: (
          <PrivateRoute>
            <AboutPage />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.LICENSING,
        element: (
          <PrivateRoute>
            <LicensingPage />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.VERIFY_CODE,
        element: <Verify2FAPage />,
      },
      {
        path: RoutePath.USER,
        element: <PrivateRoute roles={roles} />,
        children: [
          {
            path: RoutePath.PERSONAL_DATA,
            element: <UserInfoPage />,
          },
          {
            path: RoutePath.WORKSPACES,
            element: <WorkspacesPage />,
          },
          {
            path: RoutePath.ADD_WORKSPACE,
            element: <AddWorkspacePage />,
          },
        ],
      },
      {
        path: RoutePath.WORKSPACE,
        element: (
          <PrivateRoute roles={roles}>
            <WorkspacePage />
          </PrivateRoute>
        ),
        children: [
          {
            path: `${RoutePath.WORKSPACE}/:workspace`,
            children: [
              {
                path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.MANAGE_WORKSPACE}`,
                element: <ManageWorkspacePage />,
              },
              {
                path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}`,
                element: <ProductsPage />,
              },
              {
                path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}/:service`,
                element: <ProductDetailPage />,
                children: [
                  {
                    path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}/:service${RouteProductDetailsSegments.SETTINGS}`,
                  },
                  {
                    path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}/:service${RouteProductDetailsSegments.SUBSCRIPTION}`,
                  },
                  {
                    path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}/:service${RouteProductDetailsSegments.USERS}`,
                  },
                  {
                    path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}/:service${RouteProductDetailsSegments.API_ACCESS}`,
                  },
                ],
              },
              {
                path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.PRODUCTS}${RoutePath.ADD_PRODUCT}`,
                element: <AddProductPage />,
              },
              {
                path: `${RoutePath.WORKSPACE}/:workspace${RoutePath.USERS}`,
                element: <WorkspaceUsersPage />,
              },
            ],
          },
        ],
      },
      {
        path: RoutePath.NOT_FOUND_PAGE,
        element: (
          <PrivateRoute roles={roles}>
            <NotFoundPage />
          </PrivateRoute>
        ),
      },
    ],
  },
];
