export enum UserRole {
  USER = 'USER',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  SUPER_USER = 'SUPER_USER',
}

export enum UserFriendlyRole {
  MEMBER = 'Member',
  ADMIN = 'Admin',
  BILLING = 'Billing',
  SUPER_USER = 'Super User',
}

export enum AccountRoles {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  BILLING = 'BILLING',
  USER = 'USER',
}

export const ADMIN_POSTFIX = 'ADMIN';
export const MEMBER_POSTFIX = 'USER';
