import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faUser,
  faBellConcierge,
  faUsers,
  faCircleQuestion,
  faFileCertificate,
} from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

import { RoutePath } from '../../../constants/RoutePath';
import { environmentSelector, isLoadingSelector } from '../../../store/app/appSelectors';
import { isWorkspaceAdminSelector, workspaceDetailSelector } from '../../../store/workspaceDetail/workspaceDetailSelectors';
import { isSuperAdminSelector } from '../../../store/user/userSelectors';
import { Environment } from '../../../enum/appEnvironment';

const pagesLinks = () => {
  const { t } = useTranslation();

  const environment = useSelector(environmentSelector);
  const workspaceDetail = useSelector(workspaceDetailSelector);
  const isWorkspaceAdmin = useSelector(isWorkspaceAdminSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isLoading = useSelector(isLoadingSelector);

  const commonEnvLinks = useMemo(() => [
    {
      path: RoutePath.ABOUT,
      icon: <FontAwesomeIcon icon={faCircleQuestion} />,
      name: t('navPane.about'),
      key: RoutePath.ABOUT,
    },
    {
      path: RoutePath.LICENSING,
      icon: <FontAwesomeIcon icon={faFileCertificate} />,
      name: t('navPane.licensing'),
      key: RoutePath.LICENSING,
    },
  ], []);

  const userEnvLinks = useMemo(() => [
    {
      path: RoutePath.PERSONAL_DATA,
      icon: <FontAwesomeIcon icon={faUser} />,
      name: t('navPane.profile'),
      key: RoutePath.PERSONAL_DATA,
    },
    {
      path: RoutePath.WORKSPACES,
      icon: <FontAwesomeIcon icon={faFile} />,
      name: t('navPane.workspaces'),
      key: RoutePath.WORKSPACES,
    },
  ], []);

  const workspaceEnvUserLinks = useMemo(() => [
    {
      path: `${RoutePath.WORKSPACE}/${workspaceDetail?.shortName}${RoutePath.MANAGE_WORKSPACE}`,
      icon: <FontAwesomeIcon icon={faFile} />,
      name: t('navPane.manageWorkspace'),
      key: RoutePath.MANAGE_WORKSPACE,
    },
    {
      path: `${RoutePath.WORKSPACE}/${workspaceDetail?.shortName}${RoutePath.PRODUCTS}`,
      icon: <FontAwesomeIcon icon={faBellConcierge} />,
      name: t('navPane.products'),
      key: RoutePath.PRODUCTS,
    },
  ], [workspaceDetail]);

  const workspaceEnvAdminLinks = useMemo(() => [
    ...workspaceEnvUserLinks,
    {
      path: `${RoutePath.WORKSPACE}/${workspaceDetail?.shortName}${RoutePath.USERS}`,
      icon: <FontAwesomeIcon icon={faUsers} />,
      name: t('navPane.manageUsers'),
      key: RoutePath.USERS,
    },
  ], [workspaceDetail, workspaceEnvUserLinks]);

  if (environment === Environment.COMMON) {
    return commonEnvLinks;
  }

  if (environment === Environment.USER) {
    return userEnvLinks;
  }

  if (isLoading || !workspaceDetail) {
    return [];
  }

  if (isSuperAdmin || isWorkspaceAdmin) {
    return workspaceEnvAdminLinks;
  }

  return workspaceEnvUserLinks;
};

export default pagesLinks;
