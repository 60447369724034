export enum RoutePath {
  USER = '/user',
  PERSONAL_DATA = '/user/profile',
  WORKSPACES = '/user/workspaces',
  ADD_WORKSPACE = '/user/workspaces/add-workspace',
  WORKSPACE = '/workspace',
  MANAGE_WORKSPACE = '/manage-workspace',
  PRODUCTS = '/products',
  ADD_PRODUCT = '/add-product',
  LOGIN = '/login',
  VERIFY_CODE = '/verify-code',
  SIGN_UP = '/sign-up',
  VERIFY_EMAIL = '/verify-email',
  NOT_VERIFIED = '/not-verified',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  ACCEPT_INVITATION = '/accept-invitation',
  FIRST_LOGIN = '/first-login',
  USERS = '/manage-users',
  HOME_PAGE = '/',
  ABOUT = '/about',
  LICENSING = '/licensing',
  NOT_FOUND_PAGE = '*',
}
