import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IService } from '../../interfaces/IService';
import { IUser } from '../../interfaces/IUser';
import { IServiceType } from '../../interfaces/IServiceType';

export interface IServiceDetailsState {
  data: IService | null;
  isFetchingService: boolean;
  isLoadingError: boolean;
  users: IUser[];
  usersTotalCount: number;
  triggerUsers: {};
  isFetchingUsers: boolean;
  serviceType: IServiceType | null;
  isShowChangeRoleModal: boolean;
  isShowAddUserModal: boolean;
  isAdmin: boolean;
}

export const initialState: IServiceDetailsState = {
  data: null,
  isFetchingService: false,
  isLoadingError: false,
  users: [],
  usersTotalCount: 0,
  triggerUsers: {},
  isFetchingUsers: false,
  serviceType: null,
  isShowChangeRoleModal: false,
  isShowAddUserModal: false,
  isAdmin: false,
};

export const serviceDetail = createSlice({
  name: 'serviceDetail',
  initialState,
  reducers: {
    setService(state, action: PayloadAction<IService | null>) {
      state.data = action.payload;
    },
    setIsFetchingService(state, action: PayloadAction<boolean>) {
      state.isFetchingService = action.payload;
    },
    setIsLoadingError(state, action: PayloadAction<boolean>) {
      state.isLoadingError = action.payload;
    },
    setIsShowChangeRoleModal(state, action: PayloadAction<boolean>) {
      state.isShowChangeRoleModal = action.payload;
    },
    setIsShowAddUserModal(state, action: PayloadAction<boolean>) {
      state.isShowAddUserModal = action.payload;
    },
    setServiceUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },
    setUsersTotalCount(state, action: PayloadAction<number>) {
      state.usersTotalCount = action.payload;
    },
    setTriggerUsers(state) {
      state.triggerUsers = {};
    },
    setIsFetchingUsers(state, action: PayloadAction<boolean>) {
      state.isFetchingUsers = action.payload;
    },
    setServiceType(state, action: PayloadAction<IServiceType | null>) {
      state.serviceType = action.payload;
    },
    setIsServiceAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    resetServiceDetail: () => initialState,
  },
});

export const {
  setService,
  setIsFetchingService,
  setIsLoadingError,
  resetServiceDetail,
  setServiceUsers,
  setTriggerUsers,
  setUsersTotalCount,
  setIsFetchingUsers,
  setServiceType,
  setIsShowChangeRoleModal,
  setIsServiceAdmin,
  setIsShowAddUserModal,
} = serviceDetail.actions;

export default serviceDetail.reducer;
