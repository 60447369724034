import { rolesForProductDetails } from '../../../../constants/availableRoles/rolesForProductDetails';
import { SERVICE_TYPES_WITH_API_KEYS } from '../../../../constants/serviceTypesSupportingDetails';
import { UserRole } from '../../../../enum/UserRole';

export interface IProductDetailsTabItem {
  label: string;
  key: string;
  roles: UserRole[];
  serviceTypes?: number[]
  checkTrial?: boolean;
}

export enum RouteProductDetailsSegments {
  SETTINGS = 'settings',
  SUBSCRIPTION = 'subscription',
  USERS = 'users',
  USAGE_INFO = 'usage-info',
  API_ACCESS = 'api-access',
}

export const productDetailTabs = [
  {
    label: 'settings',
    key: RouteProductDetailsSegments.SETTINGS,
    roles: rolesForProductDetails,
  },
  {
    label: 'subscription',
    key: RouteProductDetailsSegments.SUBSCRIPTION,
    roles: rolesForProductDetails,
    checkTrial: true,
  },
  {
    label: 'users',
    key: RouteProductDetailsSegments.USERS,
    roles: rolesForProductDetails,
  },
  {
    label: 'apiAccess',
    key: RouteProductDetailsSegments.API_ACCESS,
    roles: rolesForProductDetails,
    serviceTypes: SERVICE_TYPES_WITH_API_KEYS,
  },
];
