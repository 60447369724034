import workspacesReducer from './workspaces/workspacesReducer';
import userReducer from './user/userReducer';
import appReducer from './app/appReducer';
import workspaceDetailReducer from './workspaceDetail/workspaceDetailReducer';
import serviceDetailReducer from './serviceDetail/serviceDetailReducer';
import servicesReducer from './services/servicesReducer';
import workspaceUsersReducer from './workspaceUsers/workspaceUsersReducer';
import serviceTypesReducer from './serviceTypes/serviceTypesReducer';
import authReducer from './auth/authReducer';
import apiKeysReducer from './apiKeys/apiKeysReducer';
import usageInfoReducer from './usageInfo/usageInfoReducer';
import subscriptionsReducer from './subscriptions/subscriptionsReducer';
import subscriptionReducer from './subscription/subscriptionReducer';

export const rootReducer = {
  workspacesReducer,
  userReducer,
  appReducer,
  workspaceDetailReducer,
  serviceDetailReducer,
  servicesReducer,
  workspaceUsersReducer,
  serviceTypesReducer,
  authReducer,
  apiKeysReducer,
  usageInfoReducer,
  subscriptionsReducer,
  subscriptionReducer,
};
