import { IServiceTypeDescrLink } from '../interfaces/IServiceType';

export const SERVICE_TYPES_WITH_API_KEYS = [
  'prepare.atomyx.io', 'trial.prepare.atomyx.io',
];

export const SERVICE_TYPES_DESCR_LINKS: Record<string, IServiceTypeDescrLink> = {
  'manage.atomyx.io.link': {
    label: 'stayInformed',
    href: 'https://atomyx.io/',
  },
};
