import {
  IAccount,
  IAccountsRequest,
  IAccountResponse,
  INewAccountData,
  IEditedAccountData,
  INewUserAccountData,
} from '../../interfaces/IAccount';
import apiInstance from './instances/apiInstances';

export const convertAnyWorkspaceNameApi = async (workspace: string): Promise<{
  accountId: number,
}> => {
  const res = await apiInstance.get(`superUser/account/convert-short-name/${workspace}`);

  return res.data;
};

export const getAnyWorkspaceApi = async (accountId: number | string): Promise<IAccount> => {
  const res = await apiInstance.get(`superUser/account/${accountId}`);

  return res.data;
};

export const getAnyWorkspacesApi = async (data: IAccountsRequest): Promise<IAccountResponse> => {
  const res = await apiInstance.get('superUser/account', {
    params: {
      ...data,
    },
  });

  return res.data;
};

export const updateAnyWorkspaceApi = async (
  accountId: number,
  data: INewAccountData | IEditedAccountData,
): Promise<IAccount> => {
  const res = await apiInstance.put(`superUser/account/${accountId}`, data);

  return res.data;
};

export const deleteAnyWorkspaceApi = async (accountId: number): Promise<IAccount> => {
  const res = await apiInstance.delete(`superUser/account/${accountId}`);

  return res.data;
};

export const addAnyWorkspaceApi = async (data: INewAccountData): Promise<IAccount> => {
  const res = await apiInstance.post('superUser/account', data);

  return res.data;
};

export const addWorkspaceApi = async (data: INewUserAccountData): Promise<IAccount> => {
  const res = await apiInstance.post('inAccount', data);

  return res.data;
};

export const convertWorkspaceNameApi = async (workspace: string): Promise<{
  accountId: number,
}> => {
  const res = await apiInstance.get(`inAccount/convert-short-name/${workspace}`);

  return res.data;
};

export const getWorkspaceApi = async (id: number | string): Promise<IAccount> => {
  const res = await apiInstance.get(`inAccount/${id}/account`);

  return res.data;
};

export const updateWorkspaceApi = async (
  id: number,
  data: INewAccountData | IEditedAccountData,
): Promise<IAccount> => {
  const res = await apiInstance.put(`inAccount/${id}/account`, data);

  return res.data;
};

export const deleteWorkspaceApi = async (accountId: number): Promise<IAccount> => {
  const res = await apiInstance.delete(`inAccount/${accountId}/account`);

  return res.data;
};
