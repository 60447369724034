import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IService } from '../../interfaces/IService';

export interface IServicesState {
  services: IService[];
  isFetchingServices: boolean;
  totalCount: number;
  triggerServices: {};
}

export const initialState: IServicesState = {
  services: [],
  isFetchingServices: false,
  totalCount: 0,
  triggerServices: {},
};

export const services = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices(state, action: PayloadAction<IService[]>) {
      state.services = action.payload;
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    setIsFetchingServices(state, action: PayloadAction<boolean>) {
      state.isFetchingServices = action.payload;
    },
    setTriggerServices(state) {
      state.triggerServices = {};
    },
    resetServicesData: () => initialState,
  },
});

export const {
  setServices,
  setIsFetchingServices,
  setTotalCount,
  setTriggerServices,
  resetServicesData,
} = services.actions;

export default services.reducer;
